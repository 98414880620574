import { graphql, Link, PageProps } from 'gatsby';
import React, { useContext, useEffect } from 'react';
import SEO from '@/components/seo';
import UIBreadcrumb from '@/components/UI/UIBreadcrumb';
import UIBox from '@/components/UI/UIBox';
import hoverCircle from '@/components/Interaction/hoverCircle';
import { Context } from '@/components/Layout';
import ContactBox from '@/components/Contact/ContactBox';

import * as textLead from '@/styles/object/text-lead.module.scss';
import * as pageHeader from '@/styles/object/page-header.module.scss';

const ServiceCategoryWebCreative: React.FC<
  PageProps<GatsbyTypes.ServiceCategoryWebCreativeQuery>
> = ({ data }) => {
  const loading = useContext(Context);
  useEffect(() => {
    hoverCircle();
  }, []);

  let contactData: readonly Pick<
    MicrocmsServiceBodyContact,
    'button' | 'title' | 'id' | 'tayori'
  >[] = [];

  contactData = data.microcmsService.contact;

  return (
    !loading && (
      <>
        <SEO
          title={`${data.microcmsService.title} | サービス`}
          description={data.microcmsService.description}
        />
        <div className="page-wrap --web-creative">
          <div className="page-wrap__wave" />
          <div className="page-wrap__bg" />
          <header className={pageHeader.page_header}>
            <h1 className={pageHeader.page_header__title}>
              <small className={pageHeader.page_header__head_text}>
                {data.microcmsService.title}
              </small>
              <div className={pageHeader.page_header__text}>
                {data.microcmsService.subTitle}
              </div>
            </h1>
          </header>
          <div className="page-contents-box">
            <div className="section">
              <p
                className={textLead.text_lead}
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                  __html: data.microcmsService.description,
                }}
              />
            </div>
            <div className="section">
              <div className="box-2rows">
                {data.allMicrocmsService.edges.map(({ node }) => (
                  <UIBox
                    key={node.serviceId}
                    title={node.title}
                    description={node.description}
                    link={`/service/${node.category}/${node.serviceId}/`}
                    displayIndex={node.displayIndex}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
        <ContactBox service={contactData} />
        <UIBreadcrumb
          UpperItem={[[`Service`, `/service`]]}
          CurrentItem="Web Creative"
        />
      </>
    )
  );
};

export const query = graphql`
  query ServiceCategoryWebCreative {
    allMicrocmsService(
      filter: {
        category: { in: "web-creative" }
        serviceId: { ne: "web-creative" }
      }
      sort: { fields: sort }
    ) {
      edges {
        node {
          id
          serviceId
          title
          category
          description
          displayIndex
        }
      }
    }
    microcmsService(serviceId: { eq: "web-creative" }) {
      id
      title
      subTitle
      description
      contact {
        button
        tayori
        title
        id
      }
    }
  }
`;

export default ServiceCategoryWebCreative;
